/**
 * @file
 * Event date widget.
 */

.event-date {
  width: 3.125rem;
  line-height: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: $font-family-sans-serif;
  border-radius: $border-radius-lg;

  .event-date-month {
    background-color: $calendar-color;
    color: $white;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    padding: map_get($spacers, 1);
    font-weight: $font-weight-semi-bold;
    text-transform: capitalize;
  }

  .event-date-day {
    font-size: map_get($font-sizes, 3);
    padding: map_get($spacers, 1);
    border: 1px solid $calendar-color;
    border-top: 0;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    color: $gray-500;
    font-weight: $font-weight-bold;
  }
}
