/**
 * @file
 * Custom site colors.
 */

//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #fff !default;
$gray-100: #f9fafd !default;
$gray-200: #edf2f9 !default;
$gray-300: #d8e2ef !default;
$gray-400: #b6c1d2 !default;
$gray-500: #9da9bb !default;
$gray-600: #748194 !default;
$gray-700: #5e6e82 !default;
$gray-800: #4d5969 !default;
$gray-900: #344050 !default;
$gray-1000: #232e3c !default;
$gray-1100: #0b1727 !default;
$black: #000 !default;


$grays: () !default;
$grays: map-merge(
    (
      'black': $black,
      '100': $gray-100,
      '200': $gray-200,
      '300': $gray-300,
      '400': $gray-400,
      '500': $gray-500,
      '600': $gray-600,
      '700': $gray-700,
      '800': $gray-800,
      '900': $gray-900,
      '1000': $gray-1000,
      '1100': $gray-1100,
      'white': $white,
    ),
    $grays
);
