/**
 * Student courses.
 */

.course-topic--body {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  blockquote {
    padding-left: 1rem;
    font-size: 1.2rem;
    border-left: 4px solid $primary;
  }

  h1, h2, h3, h4 {
    font-weight: normal;
  }
}
