/**
 * @file
 * Sidebar navs.
 */

.sidebar {
  .nav-link {
    &:hover {
      background-color: $gray-200;
    }
    &.active, &.trailing {
      background-color: $primary;
      color: $white;
    }
  }
}
