/**
 * @file
 * Accordion styles.
 */

.accordion-button {
  &.collapsed {
    background-color: tint-color($primary, 95);
  }
}
