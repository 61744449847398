/**
* @file
* Bootstrap utilities override.
*/

$utilities: map-combine(
  $utilities,
  // Override default utilities.
  (
    "color": map-merge(
      map-get($utilities, "color"),
      (
        values: map-combine(
          map-get(map-get($utilities, "color"), "values"),
          $colors,
          $grays
        )
      )
    ),
    "background-color": map-merge(
      map-get($utilities, "background-color"),
      (
        values: map-combine(
          map-get(map-get($utilities, "background-color"), "values"),
          $grays
        )
      )
    ),
    "border-bottom": map-merge(
      map-get($utilities, "border-bottom"),
      (
        values: map-merge(
          map-get(map-get($utilities, "border-bottom"), "values"),
          (
            dashed: $border-width dashed $border-color,
          )
        )
      )
    ),
    "border-color": map-merge(
      map-get($utilities, "border-color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "border-color"), "values"),
          $colors
        )
      )
    ),
    "font-weight": map-merge(
      map-get($utilities, "font-weight"),
      (
        values: map-merge(
          map-get(map-get($utilities, "font-weight"), "values"),
          (
            "medium": 500,
            "semi-bold": 600,
          )
        )
      )
    ),
    "font-size": map-merge(
      map-get($utilities, "font-size"),
      (
        values: map-merge(
          map-get(map-get($utilities, "font-size"), "values"),
          (
            "10rem": 10rem,
          )
        )
      )
    ),
  ),
  // Add new utilities.
  (
    "cursor": (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab,
    )
  )
);
