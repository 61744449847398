/**
 * @file
 * Variables that must be initialized before Bootstrap ones.
 */

$enable-shadows: true;
$enable-responsive-font-sizes: true;

// MDB color scheme
$primary: #1266f1 !default;
$secondary: #b23cfd !default;
$success: #00b74a !default;
$info: #39c0ed !default;
$warning: #ffa900 !default;
$danger: #f93154 !default;

// Extend spacers.
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4
);

// Styles from Falcon theme.
// @see https://themes.getbootstrap.com/preview/?theme_id=33181
$body-bg: $gray-300;
$box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);

// Use extra-large border radius for cards and alerts.
$border-radius-xl: .375rem;

// Cards
$card-border-radius: $border-radius-xl;
$card-border-width: 0;
$card-cap-bg: $gray-200;

// Alerts.
$alert-border-radius: $border-radius-xl;
$alert-border-width: 0;

// Make small spinner smaller.
$spinner-border-width-sm: .15em;

// Accordion.
$accordion-bg: $white;
$accordion-border-color: $gray-300;
$accordion-button-active-bg: $primary;
$accordion-button-active-color: $white;

// Navs and tabs.
$nav-tabs-link-active-bg: $white;

// Inputs.
$input-bg: $white;
