/**
 * @file
 * React Calendar.
 */

.react-calendar {
  @include media-breakpoint-down(sm) {
    width: auto;
  }
}
