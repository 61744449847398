/**
 * @file
 * Custom SASS functions.
 */

/**
 * Combines several maps into one.
 *
 * @see https://stackoverflow.com/a/30740182
 */
@function map-combine($maps...) {
  $collection: ();
  @each $map in $maps {
    $collection: map-merge($collection, $map);
  }
  @return $collection;
}
