/**
 * @file
 * Missing typography styles.
 */

.text-shadow {
  text-shadow: $text-shadow;
}

.text-align-center {
  text-align: center;
}

.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
