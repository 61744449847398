// Custom functions.
@import "functions";

// Bootstrap.
@import "../../node_modules/bootstrap/scss/functions";
@import "variables/colors";
@import "variables/bootstrap.pre";
@import "../../node_modules/bootstrap/scss/variables";
@import "variables/bootstrap.post";
@import "variables/custom";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
@import "variables/utilities";
@import "../../node_modules/bootstrap/scss/bootstrap";

// FontAwesome.
//$fa-font-path: '../../node_modules/@fortawesome/fontawesome-pro/webfonts';
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/duotone";

// React Calendar.
@import "../../node_modules/react-calendar/dist/Calendar.css";

// KaText.
@import "../../node_modules/katex/dist/katex.css";

// Typography.
@import "typography";

// Custom component styles.
@import "components/accordion";
@import "components/card";
@import "components/event-date";
@import "components/navbar";
@import "components/popover";
@import "components/react-calendar";
@import "components/sidebar";
@import "components/survey";

// Layout elements styles.

// Custom pages.
@import "pages/courses";
@import "pages/error";
