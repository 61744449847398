/**
 * @file
 * Popover styles.
 */

.popover-body {
  :last-child {
    margin-bottom: 0!important;
  }
}
