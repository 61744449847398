/**
 * @file
 * Card styles.
 */

.card-body {
  .card-title:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}
