/**
 * @file
 * SurveyJS styling.
 */

.sv-string-viewer {
  img {
    max-width: 100%;
    height: auto;
  }
}
